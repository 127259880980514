<template>
	<section>
		<section class="search-container">
			<a-form layout="inline" class="m-t-14">
				<a-form-item label="推送名称：">
					<a-input placeholder="请输入" v-model="formSearch.typeName" />
				</a-form-item>
				<a-form-item>
					<a-button type="primary" class="m-r-8" @click="btnQuery"
						><a-icon type="search" />查询</a-button
					>
					<a-button class="m-r-8" @click="btnReset"
						><a-icon type="reload" />重置</a-button
					>
					<a-button type="primary" class="m-r-8" @click="editVisible = true"
						>添加规则</a-button
					>
				</a-form-item>
			</a-form>
		</section>
		<section class="list-table-container m-t-18">
			<a-table
				size="small"
				:columns="columns"
				:data-source="tableData"
				:pagination="{
					total,
					hideOnSinglePage: true,
					current: searchData.current
				}"
				@change="pageNumChange"
			>
				<a slot="action">
					<a href="javascript: void(0)">详情</a>
				</a>
			</a-table>
		</section>
		<a-drawer
			class="drawer_module"
			title="添加推送规则"
			placement="right"
			:width="400"
			:maskClosable="false"
			:visible="editVisible"
			@close="editVisible = false"
		>
			<a-form
				:form="form"
				:colon="false"
				:labelCol="{ span: 6 }"
				:wrapperCol="{ span: 18 }"
			>
				<a-form-item label="推送名称">
					<a-input
						v-decorator="[
							'pushRuleName',
							{ rules: [{ required: true, message: '推送名称为必填项' }] }
						]"
					/>
				</a-form-item>
				<a-form-item label="预警类型">
					<a-select
						class="search-input"
						placeholder="请选择"
						:labelInValue="true"
						v-decorator="[
							'alarmType',
							{ rules: [{ required: true, message: '预警类型为必填项' }] }
						]"
					>
						<a-select-option value="">请选择</a-select-option>
						<a-select-option
							v-for="item in typeList"
							:key="item.id"
							:value="item.id"
						>
							{{ item.typeName }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="预警等级">
					<a-select
						class="search-input"
						placeholder="请选择"
						:labelInValue="true"
						v-decorator="[
							'alarmGrade',
							{ rules: [{ required: true, message: '预警等级为必填项' }] }
						]"
					>
						<a-select-option value="">请选择</a-select-option>
						<a-select-option
							v-for="item in gradeList"
							:key="item.id"
							:value="item.id"
						>
							{{ item.gradeValue }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="推送时效">
					<a-radio-group
						class="item m-t-6 checkbox-group"
						name="pushCycleType"
						:options="[
							{ value: '1', label: '实时' },
							{ value: '2', label: '周期' },
							{ value: '3', label: '定时' }
						]"
						v-decorator="[
							'pushCycleType',
							{ rules: [{ required: true, message: '推送时效为必填项' }] }
						]"
					/>
				</a-form-item>
				<a-form-item label="推送渠道">
					<a-checkbox-group
						class="item m-t-6 checkbox-group"
						name="notifyType"
						:options="[
							{ value: '1', label: '站内' },
							{ value: '2', label: '短信' },
							{ value: '3', label: '邮件' }
						]"
						v-decorator="[
							'notifyType',
							{ rules: [{ required: true, message: '推送渠道为必填项' }] }
						]"
					/>
				</a-form-item>
				<a-form-item label="推送对象">
					<a-icon type="plus-circle" @click="selectVisible = true" />
				</a-form-item>
				<a-form-item :wrapper-col="{ span: 18, offset: 6 }">
					<a-table
						class="app-table"
						size="small"
						:columns="[
							{
								title: '序号',
								dataIndex: 'key',
								align: 'center'
							},
							{
								title: '姓名',
								dataIndex: 'personnelName',
								align: 'center'
							}
						]"
						:data-source="personnelList"
						:pagination="{
							hideOnSinglePage: true
						}"
					>
						<a slot="action">
							<a href="javascript: void(0)">详情</a>
						</a>
					</a-table>
				</a-form-item>
				<a-form-item :wrapper-col="{ span: 18, offset: 6 }">
					<a-button type="primary" @click="btnConfirm">保存</a-button>
					<a-button style="margin-left: 10px" @click="btnCancel">关闭</a-button>
				</a-form-item>
			</a-form>
		</a-drawer>
		<!-- 人员选择 -->
		<a-modal
			width="1080px"
			class="modal-container"
			v-model="selectVisible"
			title="人员列表"
			:footer="false"
		>
			<selectPerson @ok="getSelectPerson" @cancel="cancelSelect" />
		</a-modal>
	</section>
</template>

<script>
	import selectPerson from '@/components/selectPerson'
	export default {
		name: 'PushRule',
		components: {
			selectPerson
		},
		data() {
			return {
				form: this.$form.createForm(this),
				typeList: [],
				gradeList: [],
				formSearch: {
					pushRuleName: ''
				},
				searchData: {
					pushRuleName: '',
					current: 1,
					size: 10
				},
				columns: [
					{
						title: '序号',
						dataIndex: 'key',
						align: 'center'
					},
					{
						title: '推送名称',
						dataIndex: 'pushRuleName',
						align: 'center'
					},
					{
						title: '预警类型',
						dataIndex: 'alarmTypeName',
						align: 'center'
					},
					{
						title: '预警等级',
						dataIndex: 'alarmGradeName',
						align: 'center'
					},
					{
						title: '推送时效',
						dataIndex: 'pushCycleType',
						align: 'center'
					},
					{
						title: '推送对象',
						dataIndex: 'notifyTargetName',
						align: 'center'
					},
					{
						title: '推送渠道',
						dataIndex: 'notifyType',
						align: 'center'
					}
					// {
					// 	title: '操作',
					// 	dataIndex: 'action',
					// 	align: 'center',
					// 	scopedSlots: { customRender: 'action' }
					// }
				],
				tableData: [],
				total: 0,
				editVisible: false,
				selectVisible: false,
				personnelList: [],
				cycleTypeDict: {
					'1': '实时',
					'2': '周期',
					'3': '定时'
				},
				notifyTypeDict: {
					'1': '站内',
					'2': '短信',
					'3': '邮件'
				}
			}
		},
		mounted() {
			this.getAlarmTypeAll()
			this.getAlarmGradeAll()
			this.queryAlarmPushRule()
		},
		methods: {
			getAlarmTypeAll() {
				this.$api.getAlarmTypeAll().then((res) => {
					if (res.code === 200) {
						this.typeList = res.data
					}
				})
			},
			getAlarmGradeAll() {
				this.$api.getAlarmGradeAll().then((res) => {
					if (res.code === 200) {
						this.gradeList = res.data
					}
				})
			},
			queryAlarmPushRule() {
				this.$api.queryAlarmPushRule(this.searchData).then((res) => {
					if (res.code === 200) {
						this.tableData = res.data.records
							? res.data.records.map((item, index) => {
									item.key =
										(this.searchData.current - 1) * this.searchData.size +
										index +
										1
									item.pushCycleType =
										this.cycleTypeDict[item.pushCycleType] ||
										item.pushCycleType ||
										''
									item.notifyType = item.notifyType
										.split(',')
										.map((nitem) => this.notifyTypeDict[nitem] || nitem)
										.toString()
									return item
							  })
							: []
						this.total = res.data.total
					}
				})
			},
			pageNumChange(pagination) {
				this.searchData.current = pagination.current
				this.queryAlarmPushRule()
			},
			btnQuery() {
				this.searchData.pushRuleName = this.formSearch.pushRuleName
				this.searchData.current = 1
				this.queryAlarmPushRule()
			},
			btnReset() {
				this.searchData.pushRuleName = this.formSearch.pushRuleName = ''
				this.searchData.current = 1
				this.queryAlarmPushRule()
			},
			editAlarmType(record) {
				this.dataSelected = record
				this.editVisible = true
			},
			btnConfirm() {
				this.form.validateFields((errors, values) => {
					if (!errors) {
						this.$api
							.insertAlarmPushRule({
								pushRuleName: values.pushRuleName,
								alarmType: values.alarmType.key,
								alarmTypeName: values.alarmType.label,
								alarmGrade: values.alarmGrade.key,
								alarmGradeName: values.alarmGrade.label,
								pushCycleType: values.pushCycleType.toString(),
								pushCycleUnit: '',
								pushCycleValue: '',
								notifyType: values.notifyType.toString(),
								notifyTargetId: this.personnelList
									.map((item) => item.id)
									.toString(),
								notifyTargetName: this.personnelList
									.map((item) => item.personnelName)
									.toString()
							})
							.then((res) => {
								if (res.code === 200) {
									this.editVisible = false
									this.queryAlarmPushRule()
								}
							})
							.catch((err) => {
								console.log(err)
							})
					} else {
						return false
					}
				})
			},
			btnCancel() {
				this.form.resetFields()
				this.editVisible = false
			},
			getSelectPerson(list) {
				list.forEach((item, ind) => {
					item.key = ind + 1
				})
				this.selectVisible = false
				this.personnelList = [...list]
				console.log(this.personnelList)
			},
			cancelSelect() {
				this.selectPersonModal.visible = false
				this.personnelList = []
			},
			deletePerson(list) {
				this.personnelList = this.personnelList.filter(
					(item) => item.id !== list.id
				)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.checkbox-group {
		/deep/ {
			.ant-checkbox-wrapper {
				color: #ffffff;
			}
		}
	}
	.app-table {
		/deep/ {
			.ant-table,
			.ant-table-thead > tr > th {
				color: #ffffff;
			}
		}
	}
</style>
